/* @jsx jsx */
import React from 'react'
import { Button, Heading, jsx, Flex } from 'theme-ui'
import PropTypes from 'prop-types'
import FadeInWrapper from '../../Generic/FadeInWrapper'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import useVariantAvailability from '../../../hooks/components/use-variant-availability'
import { toURL } from '~/utils/intl/localePrefix'
import StickerTag from './StickerTag'
import StarRating from '../StarRating'

export const ProductGridItem = ({
  item,
  isFeatured,
  sticker,
  onClick,
  onNoAvailability,
  locale,
}) => {
  const { trackClickProduct } = useAnalytics()
  const { isAvailable } = useVariantAvailability({ sku: item.sku })
  const { product, size, price, productGridImage, sku } = item
  const { name, slug, productType } = product[0]
  const [added, setAdded] = React.useState(false)

  const handleAddToCart = async e => {
    setAdded(true)
    e.preventDefault()
    await onClick(sku, 1)
  }

  const onItemOutOfStock = item => {
    setAdded(true)
    onNoAvailability(item)
  }

  let label = !isAvailable
    ? 'Join Waitlist'
    : (size ? `Get ${size}` : 'Buy Now') + ` | $${price}`

  if (added) {
    label = isAvailable ? 'Added to Cart' : 'On Waitlist'
  }

  let slugToUse = `/products/${slug}`
  const isVarietyPack =
    slug === 'variety-pack-product' && !!productType && !!productType.slug
  const trackedSlug = isVarietyPack ? productType.slug : slug
  if (isVarietyPack) {
    slugToUse = `/bundles/${productType.slug}`
  }

  const localizedLink = toURL(`/${locale}/`, slugToUse)

  return (
    <FadeInWrapper
      key={localizedLink}
      forwardSx={{ gridColumn: isFeatured ? '1 / span 2' : 'auto' }}
    >
      <Flex
        sx={{
          flexFlow: 'column nowrap',
          width: '100%',
          position: 'relative',
          alignItems: 'center',
          minWidth: ['168px', '200px'],
          justifyContent: 'space-between',
        }}
      >
        <div
          sx={{
            flex: 1,
            position: 'relative',
            textAlign: 'center',
          }}
        >
          {sticker && (
            <StickerTag
              title={sticker.title}
              stroke={sticker.stroke}
              fill={sticker.fill}
            />
          )}
          <a
            key={localizedLink}
            href={localizedLink}
            onClick={() => trackClickProduct(trackedSlug)}
          >
            <div
              sx={{
                height: '220px',
                position: 'relative',
                width: '100%',
                mx: 'auto',
              }}
            >
              {productGridImage && (
                <img
                  src={productGridImage && productGridImage.fixed.src}
                  alt={productGridImage.title}
                  sx={{
                    position: 'relative',
                    zIndex: 2,
                    height: '100%',
                    maxHeight: '220px',
                    width: '100%',

                    objectFit: 'contain',
                  }}
                />
              )}
            </div>
          </a>
          <a
            href={localizedLink}
            onClick={() => trackClickProduct(trackedSlug)}
          >
            <Heading
              as="h4"
              sx={{
                color: 'governorBay',
                fontSize: [18, 24],
                lineHeight: '24px',
                fontWeight: 900,
                textAlign: 'center',
                whiteSpace: 'nowrap',
                mt: [24],
                mb: 10,
              }}
            >
              {name}
            </Heading>
          </a>
        </div>
        <StarRating
          product={product[0]}
          forwardSx={{ mb: '1.5rem', color: 'governorBay' }}
        />
        <Button
          bg={added ? '#FFDD00' : 'governorBay'}
          color={added ? 'governorBay' : '#FFDD00'}
          as="a"
          onClick={isAvailable ? handleAddToCart : () => onItemOutOfStock(item)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '50px',
            paddingX: '16px',
            maxWidth: ['180px', '200px'],
            fontSize: [16, 20],
            lineHeight: ['24px', '25.77px'],
            fontWeight: 800,
          }}
        >
          {label}
        </Button>
      </Flex>
    </FadeInWrapper>
  )
}

export const FluidImgPropType = PropTypes.shape({
  aspectRatio: PropTypes.number,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
})

export const GridItemPropType = PropTypes.shape({
  price: PropTypes.number.isRequired,
  size: PropTypes.string,
  sku: PropTypes.string,
  productGridImage: PropTypes.object,
  onClick: PropTypes.func,
  product: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          fluid: FluidImgPropType,
        }),
      ).isRequired,
    }),
  ),
})

ProductGridItem.propTypes = {
  item: GridItemPropType,
  sticker: PropTypes.shape({
    title: PropTypes.string.isRequired,
    stroke: PropTypes.string.isRequired,
    fill: PropTypes.string.isRequired,
  }),
  isFeatured: PropTypes.bool,
}
